import React from "react";
import "./css/Copyright.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "@mui/material";

function Copyright() {
  return (
    <div className="copyrightMain">
      <div className="copyrightText">
        <p>Copyright 2023. Eldorado BioTech</p>
      </div>

      <div className="socialIcons">
        <div>
          <Link
            href="https://www.facebook.com/ebpl.vj?mibextid=ZbWKwL"
            target="_blank"
          >
            <FacebookIcon />
          </Link>
        </div>
        <div>
          <Link
            href="https://www.instagram.com/eldoradobiotech/"
            target="_blank"
          >
            <InstagramIcon />
          </Link>
        </div>
        <div>
          <Link href="https://www.linkedin.com/company/el-dorado-biotech-pvt-ltd/" target="_blank">
            <LinkedInIcon />
          </Link>
        </div>
        <div>
          <Link href="https://youtube.com/@eldoradobiotech4734 " target="_blank">
            <YouTubeIcon />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Copyright;
