import React from "react";
import "./css/BlogBox.css";
import Button from "./Button";
function BlogBox({ blog, bgColor }) {
  return (
    <div className="blog-box" style={{ backgroundColor: bgColor }}>
      <div className="blog-box-in">
        <div className="blog-content">
          <div className="blog-img">
          <img
            src={
              "https://drive.google.com/thumbnail?id=" +
              String(blog.photo1).split("=")[1]
            }
            alt="Blog Image"
            />
            </div>
          <h3>{blog.title}</h3>
          <div className="blog-body">{blog.content.slice(0, 120) + "..."}</div>
          <i>By {blog.author}</i>
          <div className="blog-page-btn">
            <span>{blog.time} min</span>
            <a href={"/blogpage/" + String(blog.id)}>
              {" "}
              <Button cName="primary-btn" text="View Blog" />{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogBox;
