import React from "react";
import './css/Carousal.css'
import i1 from '../images/Homepage/carouselSet1/20.webp'
import i2 from '../images/Homepage/carouselSet1/21.webp'
import i3 from '../images/Homepage/carouselSet1/22.webp'
import i4 from '../images/Homepage/carouselSet1/23.webp'
import i5 from '../images/Homepage/carouselSet1/24.webp'
import i6 from '../images/Homepage/carouselSet1/25.webp'
import i7 from '../images/Homepage/carouselSet1/26.webp'


function Carousal() {
  return (
    <div className="full">
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
              src={i1}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i2}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i3}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i4}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i5}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i6}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i7}
              height="100"
              width="250"
              alt=""
            />
          </div>

          {/* Start from here */}

          <div class="slide">
            <img
              src={i1}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i2}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i3}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i4}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i5}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i6}
              height="100"
              width="250"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={i7}
              height="100"
              width="250"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousal;