import React, { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import BlogBox from "../components/BlogBox";
import "./css/Blogs.css";

function Blogs() {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('blogsAnimateSubHeading');
          } else {
            entry.target.classList.remove('blogsAnimateSubHeading');
          }
        });
      },
      { threshold: 0.5 }
    );

    const the_animation = document.querySelectorAll('.blogsAnimateSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }

    return () => {
      observer.disconnect();
    };
  }, []);


  const { data, loading, error, exists } = useFetch();
  if (!loading && exists) {
    return (
      <main>
        <Navbar />
        <div className="blogs-hero">
          <div className="blogs-hero-title">
            <h1 className="blogsAnimateHeading">Blogs</h1>
          </div>
        </div>

        <div className="blog-section">
          <div className="blogsBG">
            <h2 className="blogsAnimateSubHeading">Learn with our resources</h2>
            <br />
            <div className="blogs">
              {data.map((blog) => (
                <BlogBox blog={blog} bgColor="#eaf4fa" />
              ))}
            </div>
          </div>
        </div>
        <Footer />
        <Copyright />
      </main>
    );
  }
  // Check for internet error
  if (error) {
    return (
      <h1
        style={{
          fontSize: "10vh",
          width: "80vw",
          textAlign: "center",
          margin: "42.5vh auto",
        }}
      >
        Something went wrong. Check your internet connection...
      </h1>
    );
  }
  // Check if post has stopped loading and post does not exist.
  if (!loading && !exists) {
    window.open("/*", "_self");
  }
  if (loading) {
    return (
      <>
        <h1
          style={{
            fontSize: "clamp(32px, 2vw, 48px)",
            fontWeight: "600",
            width: "80vw",
            textAlign: "center",
            margin: "45vh auto",
          }}
        >
          Loading...
        </h1>
      </>
    );
  }
}

export default Blogs;
