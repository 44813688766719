import React from "react";
import "./css/OneBlog.css";
//Hooks
import useFetch from "../hooks/useFetch";
// it takes human count id of the blog and returns the entire page of the blog
function OneBlog({ id }) {
  const { data, loading, error } = useFetch("all");
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error occurred while fetching data.</div>;
  }

  const blog = data[id - 1];

  if (!blog) {
    return <div>Blog not found.</div>;
  }

  return (
    <div className="fullpage">
      <div className="heading">
        <div className="heading-text">{blog.title}</div>
      </div>
      <div className="page-body">
        <div className="author">
          By <span>{blog.author}</span>
        </div>
        <div className="date">Uploaded {blog.date}</div>
        <div className="time">{blog.time} mins</div>
        <div className="picture">
          <img
            src={
              "https://drive.google.com/thumbnail?id=" +
              String(blog.photo1).split("=")[1]
            }
            alt="Blog-Pic"
          />
        </div>
        <div className="one-blog-content"><pre>{blog.content}</pre></div>
      </div>
    </div>
  );
}

export default OneBlog;
