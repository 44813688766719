import { useState, useEffect } from "react";
import React from "react";
import "./css/Navbar.css";
import logo from "../images/logoHeader.webp";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

function Navbar() {
  const bodyStyle = document.body.style;
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isLocked, setIsLocked] = useState(bodyStyle.overflowY === 'hidden')
  useEffect(()=>{
    bodyStyle.overflowY = isLocked ? 'hidden' : 'auto'
  }, [isLocked, bodyStyle])
  const toggle = () => setIsLocked(!isLocked);


  return (
    <nav className="nav">
      <div className="brandLogo">
        <a href="/">
          <img src={logo} loading="lazy" alt="EldoradoLogo" />
        </a>
      </div>

      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
          toggle();
        }}
      >
        <MenuRoundedIcon />
      </button>

      <div className={isNavExpanded ? "nav-menu expanded" : "nav-menu"}>
        <ul>
          <li>
            <a href="/aboutus">About</a>
          </li>
          <li>
            <a href="/products">Products</a>
          </li>
          <li>
            <a href="/events">Events</a>
          </li>
          <li>
            <a href="/blogs">Blogs</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
