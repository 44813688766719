import React, { useEffect } from "react";
import "./css/Homepage.css";

//components
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import BlogBox from "../components/BlogBox";
import CardCarousel from "../components/CardCarousel";
import Carousal from "../components/Carousal";
import CarousalLR from "../components/CarousalLR";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Mission from "../components/Mission";

//images
import year20 from "../images/Homepage/20Years.webp";
import couple from "../images/Homepage/oldCouple.webp";
import heroTextImg from "../images/Homepage/heroLogo1web.webp";
import leftDesign from "../images/leftDesign.png";
import rightDesign from "../images/rightDesign.png";

//Hooks
import useFetch from "../hooks/useFetch";

function Homepage() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animateSubHeading', 'animateTitleHero');
          } else {
            entry.target.classList.remove('animateSubHeading', 'animateTitleHero');
          }
        });
      },
      { threshold: 0.5 }
    );


    const the_animation = document.querySelectorAll('.animateSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }
    return () => {
      observer.disconnect();
    };
  }, []); 

  const { data } = useFetch("all");
  const latestBlogs = data.slice(0, 3);
  return (
    <main>
      <Navbar />

      <div className="hero-section">
        <div id="leftDesign">
          <img src={leftDesign} alt="left" />
        </div>
        <div id="rightDesign">
          <img src={rightDesign} alt="left" />
        </div>
        <div className="hero-text-img">
          <img src={heroTextImg} alt="eldorado" />
        </div>
        <div className="heroTitle">
          <div id="animateBoxId">
            <h1 className="animateTitleHero">Health Solutions</h1>
          </div>
        </div>
        <div className="hero-subtitle">
          <h3 className="typeText">
            Our Commitment to Your Health and Wellness
          </h3>
        </div>

        <div className="btn-space">
          <a href="/contact">
            {" "}
            <Button cName="secondary-btn" text="Get in touch" />{" "}
          </a>
          <a href="/products">
            <Button cName="primary-btn" text="Products" />{" "}
          </a>
        </div>

        <div className="hero-img">
          <div className="animateHeroImg">
            <img src={couple} alt="Old Couple" />
          </div>
        </div>
      </div>
      <div className="what-we-do">
        <h2 className="animateSubHeading">Who Are We?</h2>
        <p>
          Welcome to El-dorado Biotech Pvt. Ltd., your trusted partner in
          overcoming diseases and enhancing overall health and well-being. With
          a strong dedication to chronic therapy, we proudly introduce six
          groundbreaking brands, the first of their kind in India. Our mission
          is to revolutionise treatment, empower individuals, and make a lasting
          impact on healthcare. Join us on the path to victory over diseases as
          we enhance lives and contribute to a healthier future.
        </p>
        <a href="/aboutus">
          <Button cName="primary-btn" text="Know more" />
        </a>
        <div className="year20 animate20Years">
          <img src={year20} alt="20 years of excellence" />
        </div>
      </div>
      <div className="auto-carousel-section">
        <h2 className="animateSubHeading">Our Brands</h2>
        <Carousal />
        <br />
        <CarousalLR />
        <br />
      </div>
      <br />
      <br />
      <div className="product-section">
        <h2 className="animateSubHeading">Product Portfolio</h2>
        <br />
        <CardCarousel />
        <a href="/products">
          <Button cName="primary-btn" text="View all" />
        </a>
      </div>

      <div className="missionVission">
        <div id="leftDesign">
          <img src={leftDesign} alt="left" width={200} />
        </div>
        <div id="rightDesign">
          <img src={rightDesign} alt="right" width={200} />
        </div>
        <div className="textMission">
          
          <h3 className="animateSubHeading">Aspiring Statures of Medicare With </h3>
          <h2 className="animateSubHeading">Golden Touch of Healthcare</h2>
        </div>
        <Mission />
      </div>
      <br />
      <div className="blog-section" style={{ backgroundColor: "#EAF4FA" }}>
        <h2 className="animateSubHeading">Learn with our resources</h2>
        <br />
        <div className="blogs">
          {latestBlogs.map((blog) => (
            <BlogBox blog={blog} bgColor="white" />
          ))}
        </div>
        <div className="home-blog-btn">
          <a href="/blogs">
            <Button cName="primary-btn" text="View all blogs" />
          </a>
        </div>
      </div>
      <Footer />
      <Copyright />
    </main>
  );
}

export default Homepage;
