import React, { useEffect }  from 'react';
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";

import grp_pic from "../images/AboutUs/aboutGrpImgweb.webp";
import leftDesign from "../images/leftDesign.png";
import rightDesign from "../images/rightDesign.png";
import socialFoundationLogo from "../images/AboutUs/socialFoundationLogoweb.webp";
import sankrit from "../images/AboutUs/aboutServiceImgweb.webp";

import "./css/AboutUs.css";

function AboutUs() {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('aboutUsAnimateSubHeading');
          } else {
            entry.target.classList.remove('aboutUsAnimateSubHeading');
          }
        });
      },
      { threshold: 0.5 }
    );

    const the_animation = document.querySelectorAll('.aboutUsAnimateSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }

    return () => {
      observer.disconnect();
    };
  }, []); 
  return (
    <main>
      <Navbar />
      <div className="about-hero">
        <div className="about-hero-title">
          <h1 className="aboutUsAnimateHeading">El-Dorado BioTech Pvt.Ltd </h1>
        </div>
        <div className="about-info">
          <div className="content">
            <h2 className="aboutUsAnimateHeading">About Us</h2>
            <p>
              Established in the year 2002, Eldorado is a pioneering healthcare
              company that is dedicated to improving both physical and mental
              well-being. We firmly believe that a balanced approach to health
              is the key to true happiness. As an integrated healthcare
              solutions provider, we have developed an innovative range of
              products that cater to over 10 therapeutic areas, including
              cardiovascular, anti-diabetics, analgesics, gastrointestinal,
              haematinics, anti-infectives and antibiotics, respiratory agents,
              and more.
            </p>
          </div>
        </div>
      </div>

      <div className="trust">
        <div id="leftDesignabout">
          <img src={leftDesign} alt="left" />
        </div>
        <div id="rightDesignabout">
          <img src={rightDesign} alt="left" />
        </div>
        <h2 className="aboutUsAnimateSubHeading">Trust and Excellence in Healthcare</h2>
        <br />
        <p>
          At Eldorado, we are committed to delivering trust and excellence in
          healthcare. Our products have earned the trust of healthcare
          professionals, including respected doctors who rely on our solutions
          to deliver effective treatments. With a proven track record of
          delivering results, our products consistently demonstrate their
          efficacy and reliability. We take pride in the confidence healthcare
          professionals place in us, recognizing our unwavering commitment to
          quality and the positive outcomes our products provide. By combining
          trusted solutions with the expertise of healthcare professionals, we
          drive positive change in the healthcare landscape, improving patient
          outcomes every step of the way.
        </p>
      </div>

      <div className="about-grp-pic" >
        <div className="grp-pic-content">
          <img src={grp_pic} alt="EldoradoTeam" />
        </div>
      </div>

      <div className="values-section" >
        <div className="values-content">
          <div id="leftDesignabout">
            <img src={leftDesign} alt="left" />
          </div>
          <div id="rightDesignabout">
            <img src={rightDesign} alt="left" />
          </div>
          <div className="about-hero-title-values">
            <h2 className="aboutUsAnimateSubHeading">
              Company's Philosophy & Values
            </h2>
          </div>
          <div className="values-body">
            <div className="grid-container">
              <div className="tile">
                <div className="values-header">
                  <h1>L</h1>EADERSHIP
                </div>
              </div>
              <div className="tile">
                <div className="values-header">
                  <h1>I</h1>NNOVATION
                </div>
              </div>
              <div className="tile">
                <div className="values-header">
                  <h1>F</h1>UTURE &emsp; &ensp; &ensp;
                </div>
              </div>
              <div className="tile">
                <div className="values-header">
                  <h1>E</h1>XPERIENCE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="director-section">
        <div className="director-content">
          <h2 className="aboutUsAnimateSubHeading">Company's Directors</h2>
          <div className="director-info vinay-box">
            <div className="director-img" id="vinay-img">
              {" "}
            </div>
            <div className="director-body">
              <h3>Mr. Vinay Joshi - Managing Director</h3>
              <p>
                With extensive experience in the pharmaceutical field, Mr. Vinay
                Vasant Joshi has been instrumental in driving Eldorado's
                success. His journey from a Medical Representative to Managing
                Director is an inspiration. Recognized for his outstanding
                performance, he holds a "Doctorate of Professional
                Entrepreneurship in Business Management" from the European
                Continental University. Mr. Joshi has been honoured with the
                prestigious "Business Leadership Award" and the esteemed "Young
                Entrepreneur Award" for his exceptional achievements in business
                and social services.
              </p>
            </div>
          </div>

          <div className="director-info vandana-box">
            <div className="director-body">
              <h3>Message from the Managing Director:</h3>
              <p>
                <i>
                  "Enjoy your work in a positive and enriching atmosphere.
                  Unleash your hidden qualities and talents to pave the way for
                  a brighter future. Let your work be driven by the desire to
                  contribute to the betterment of mankind and the healing of
                  others' pain. Together, we can make a meaningful difference
                  and create a world filled with compassion and well-being."
                </i>
              </p>
              <br />
              <h3>Mrs. Vandana Joshi - Director</h3>
              <p>
                Mrs. Vandana Vinay Joshi, a prominent figure in the
                pharmaceutical field, began her career as a Medical
                Representative with renowned firms. With a deep sense of empathy
                for society, she embodies philanthropy and possesses a genuine
                passion for fostering a healthy work environment. Mrs. Joshi is
                known for her love for nature and her commitment to creating a
                positive impact on the community.
              </p>
              <br />
            </div>
            <div className="director-img" id="vandana-img">
              {" "}
            </div>
          </div>
        </div>
      </div>

      <div className="social-foundation">
        <div className="social-content">
          <div id="leftDesignabout">
            <img src={leftDesign} alt="left" />
          </div>
          <div id="rightDesignabout">
            <img src={rightDesign} alt="left" />
          </div>
          <div className="socialFoundationLogo aboutUsAnimateSubHeading">
            <img src={socialFoundationLogo} alt="socialFoundationLogo" />
          </div>
          <img src={sankrit} alt="social" />
          <h3>
            <b>
              <i>Empowering Lives through Service</i>
            </b>
          </h3>
          <br />
          <p>
            At Eldorado Social Foundation, we are passionately engaged in
            conducting social activities with the sole purpose of spreading
            happiness. Our vision is to actively participate and contribute to
            every individual's journey in achieving their fullest potential
            across all aspects of life. Guided by a non-commercial platform, our
            mission is to assist humanity in every possible way, serving the
            betterment of society.
          </p>
          <br />
          <br />
          <h3>
            <b>"Inspiring Change, Fueled by Philanthropy"</b>
          </h3>
          <br />
          <a href="/contact">
            <Button cName="primary-btn" text="Get in touch" />
          </a>
        </div>
      </div>
      <Footer />
      <Copyright />
    </main>
  );
}

export default AboutUs;
