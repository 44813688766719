import React from "react";
import "./css/Mission.css";
import Button from "./Button";

function Mission() {
  return (
    <div className="fullMission">
      <div className="blocksMission">
        <div className="missionBlock">
          <div className="mvText">
            <b>Mission</b>
          </div>
          <div className="mainMVText">
            <p>At El-dorado Biotech, our mission is to provide superior medicines
            at affordable prices, placing our customers' well-being at the
            forefront. Through innovation and unwavering dedication, we strive
            to make a positive impact on global healthcare and improve lives.</p>
          </div>
        </div>
        <div className="visionBlock">
          <div className="mvText">
            <b>Vision</b>
          </div>
          <div className="mainMVText">
            <p>At El-dorado Biotech, our vision is to positively impact millions of
            lives by expanding our reach and ensuring widespread access to our
            products. We strive to reach every corner of the country, providing
            our high-quality healthcare solutions to more and more people each
            day.</p>
          </div>
        </div>
      </div>
      <div className="buttonLearnMore">
        <a href="/aboutus">
          <Button cName="primary-btn" text="Learn More" />
        </a>
      </div>
    </div>
  );
}

export default Mission;
