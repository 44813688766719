import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Carousel from "react-elastic-carousel";
import leftDesign from "../images/leftDesign.png";
import rightDesign from "../images/rightDesign.png";

import EventCarousel from "../components/EventCarousel";
import "./css/Events.css";

import {
  gallery_img1,
  gallery_img2,
  gallery_img3,
  gallery_img4,
  gallery_img5,
  gallery_img6,
  gallery_img7,
  gallery_img8,
} from "../images/Events/Gallery";

import {
  training_img1,
  training_img2,
  training_img3,
  training_img4,
  training_img5,
  training_img6,
  training_img7,
} from "../images/Events/Training";

import {
  conference_img1,
  conference_img2,
  conference_img3,
  conference_img4,
  conference_img5,
} from "../images/Events/Conference";

import {
  candid_img1,
  candid_img2,
  candid_img3,
  candid_img4,
  candid_img5,
  candid_img6,
  candid_img7,
} from "../images/Events/Candid";

function Events() {

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('eventsAnimateSubHeading');
          } else {
            entry.target.classList.remove('eventsAnimateSubHeading');
          }
        });
      },
      { threshold: 0.5 }
    );

    const the_animation = document.querySelectorAll('.eventsAnimateSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }

    return () => {
      observer.disconnect();
    };
  }, []); 


  const galleryImgs = [
    gallery_img1,
    gallery_img2,
    gallery_img3,
    gallery_img4,
    gallery_img5,
    gallery_img6,
    gallery_img7,
    gallery_img8,
  ];

  const trainingImgs = [
    training_img1,
    training_img2,
    training_img3,
    training_img4,
    training_img5,
    training_img6,
    training_img7,
  ];

  const conferenceImgs = [
    conference_img1,
    conference_img2,
    conference_img3,
    conference_img4,
    conference_img5,
  ];

  const candidImgs = [
    candid_img1,
    candid_img2,
    candid_img3,
    candid_img4,
    candid_img5,
    candid_img6,
    candid_img7,
  ];

  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1024, itemsToShow: 3 },
  ];
  let carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(candidImgs.length - 1);
    }
  };
  return (
    <main>
      <Navbar />
      {/* Hero Section */}
      <div className="events-hero">
        <div className="events-hero-title">
          <h1 className="eventsAnimateHeading">Life at El-dorado</h1>
        </div>
      </div>
      <div className="event-section gallery">
        <div className="event-content">
          <h2 className="eventsAnimateSubHeading">Gallery</h2>
          <EventCarousel listOfImgs={galleryImgs} itemsToShow={1} />
        </div>
      </div>

      <div className="event-section training">
        <div className="event-content">
          <div id="leftDesignabout">
            <img src={leftDesign} alt="left" />
          </div>
          <div id="rightDesignabout">
            <img src={rightDesign} alt="left" />
          </div>
          <h2 className="eventsAnimateSubHeading">Training</h2>
          <EventCarousel listOfImgs={trainingImgs} itemsToShow={1} />
        </div>
      </div>

      <div className="event-section conference">
        <div className="event-content">
          <h2 className="eventsAnimateSubHeading" >Participation in Conference</h2>
          <EventCarousel listOfImgs={conferenceImgs} itemsToShow={1} />
        </div>
      </div>

      <div className="event-section candid">
        <div className="event-content">
          <div id="leftDesignabout">
            <img src={leftDesign} alt="left" />
          </div>
          <div id="rightDesignabout">
            <img src={rightDesign} alt="left" />
          </div>
          <h2 className="eventsAnimateSubHeading" >Few Candid Moments</h2>
          <Carousel
            ref={carouselRef}
            pagination={true}
            breakPoints={breakpoints}
            onPrevStart={onPrevStart}
            onNextStart={onNextStart}
            disableArrowsOnEnd={false}
          >
            {candidImgs.map((imgLink) => (
              <div className="candid-img-card">
                <img src={imgLink} alt={imgLink} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <Footer />
      <Copyright />
    </main>
  );
}

export default Events;
