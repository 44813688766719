import React from "react";
import Carousel from "react-elastic-carousel";
import "./css/EventCarousel.css";

function EventCarousel({ listOfImgs, itemsToShow }) {
  let carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(listOfImgs.length - 1);
    }
  };
  return (
    <div className="event-carousel">
      <Carousel
        ref={carouselRef}
        pagination={true}
        itemsToShow={itemsToShow}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
      >
        {listOfImgs.map((imgLink) => (
          <div className="event-img-card">
            <img src={imgLink} alt={imgLink} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default EventCarousel;
