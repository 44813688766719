import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/HomePage";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import Events from "./pages/Events";
import Blogs from "./pages/Blogs";
import ContactUs from "./pages/ContactUs";
import BlogPage from "./pages/BlogPage";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/events" element={<Events />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blogpage/:id" element={<BlogPage/>} />
      </Routes>
    </div>
  );
}

export default App;
