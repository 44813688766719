import React from "react";
import "./css/Footer.css";
import White_eldo from "../images/logoWhiteFooter.webp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

function Footer() {
  return (
    <div className="mainFooter">
      <div className="eldoLogo">
        <img src={White_eldo} alt="Logo" />
      </div>

      <div className="line"></div>

      <div className="footerText">
        <div className="footerMenu">
          <ul>
            <h3>
              <a href="/aboutus">
                <li>About</li>
              </a>
            </h3>
            <h3>
              <a href="/products">
                <li>Products</li>
              </a>
            </h3>
            <h3>
              <a href="/events">
                <li>Events</li>
              </a>
            </h3>
            <h3>
              <a href="/blogs">
                <li>Blogs</li>
              </a>
            </h3>
            <h3>
              <a href="/contact">
                <li>Contact</li>
              </a>
            </h3>
          </ul>
        </div>

        <div className="footerAddress">
          <div className="address">
            <h3>
              <b>Address</b>
            </h3>
          </div>
          <br />
          <div className="addressText">
            <div className="locationIcon">
              <LocationOnIcon />
            </div>
            <div className="locationText">
              <a
                href="https://maps.app.goo.gl/tGrAnwUdb3a2nh5t8"
                target="_blank"
              >
                <p>
                  A-Citius, 405, Space Olympia, Sutgirni Chowk, Aurangabad,
                  Maharashtra, 431001
                </p>
              </a>
            </div>
          </div>
          <br />
          <div className="addressText">
            <div className="locationIcon">
              <LocationOnIcon />
            </div>
            <div className="locationText">
              <a
                href="https://maps.app.goo.gl/ns1w3AAgyVAcHgqb8"
                target="_blank"
              >
                <p>
                  601/602, Seven Business Square, Ganesh Khind, University Road,
                  Shivaji Nagar, Pune
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="footerContact">
          <h3>
            <b>Contact Us</b>
          </h3>
          <br />
          <div className="contactText">
            <div className="callIcon">
              <CallIcon />
            </div>
            <div className="phone">
              <a href="tel:+91-240-2983136">
                <p>+91-240-2983136</p>
              </a>
            </div>
          </div>
          <br />

          <div className="contactText">
            <div className="EmailIcon">
              <EmailIcon />
            </div>
            <div className="EmailText">
              <p>
                <a href="mailto:info@eldoradobiotech.com">
                  info@eldoradobiotech.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
