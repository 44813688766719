import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import OneBlog from "../components/OneBlog";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import BlogBox from "../components/BlogBox";
import Button from "../components/Button";
function BlogPage() {
  const { id } = useParams();
  const { data } = useFetch("all");
  const latestBlogs = data.slice(0,3);
  return (
    <main>
      <Navbar />
      <hr />

      <OneBlog id={id} />

      <div className="blog-section" style={{ backgroundColor: "#EAF4FA" }}>
        <h2>More Blogs</h2>
        <br />
        <div className="blogs">
          {latestBlogs.map((blog) => (
            <BlogBox blog={blog} bgColor="white" />
          ))}
        </div>
        <div className="home-blog-btn">
          <a href="/blogs">
            <Button cName="primary-btn" text="View all blogs" />
          </a>
        </div>
      </div>

      <Footer />
      <Copyright />
    </main>
  );
}

export default BlogPage;
