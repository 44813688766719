import React from 'react'
import './css/Button.css'

const Button = ({cName, text, type}) => {
  return (
    <button className={cName} type={type}>
      {text}
    </button>
  )
}

export default Button
