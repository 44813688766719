import React from "react";
import './css/CarousalLR.css'
import img1 from '../images/Homepage/carouselSet2/1.webp'
import img2 from '../images/Homepage/carouselSet2/2.webp'
import img3 from '../images/Homepage/carouselSet2/3.webp'
import img4 from '../images/Homepage/carouselSet2/4.webp'
import img5 from '../images/Homepage/carouselSet2/5.webp'
import img6 from '../images/Homepage/carouselSet2/6.webp'
import img7 from '../images/Homepage/carouselSet2/7.webp'

function CarousalLR() {
  return (
    <div className="full">
      <div class="slider">
        <div class="slide-track" style={{ animationDirection: "reverse" }}>
          <div class="slide">
            <img
              src={img1}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img2}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img3}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img4}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img5}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img6}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img7}
              alt=""
            />
          </div>

          {/* Start from here */}
          <div class="slide">
            <img
              src={img1}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img2}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img3}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img4}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img5}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img6}
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src={img7}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarousalLR;
