import React from "react";
import ProductBox from "./ProductBox";
import Carousel from "react-elastic-carousel";
import "./css/CardCarousel.css";

//images
import neuro from "../images/Card_Carousel/neuro.webp";
import cardio from "../images/Card_Carousel/cardio.webp";
import women_health from "../images/Card_Carousel/women_health.webp";
import orthopedic from "../images/Card_Carousel/orthopedic.webp";
import diabetic from "../images/Card_Carousel/diabetic.webp";
import pediatric from "../images/Card_Carousel/pediatric.webp";


const CardCarousel = () => {
  let carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(5);
    }
  };
  const breakpoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 480, itemsToShow: 2, itemsToScroll: 2 },
    { width: 1024, itemsToShow: 3, itemsToScroll: 3 },
  ];

  
    return (
      <div className="slider-container">
        <Carousel
          ref={carouselRef}
          breakPoints={breakpoints}
          transitionMs={750}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
        >
          <ProductBox title="Cardiology" imgLink={cardio} />
          <ProductBox title="Diabetes" imgLink={diabetic} />
          <ProductBox title="Neurology" imgLink={neuro} />
          <ProductBox title="Orthopedics" imgLink={orthopedic} />
          <ProductBox title="Women's Health" imgLink={women_health} />
          <ProductBox title="Pediatrics" imgLink={pediatric} />
        </Carousel>
      </div>
    );
};

export default CardCarousel;
