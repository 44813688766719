import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import ProductCarousel from "../components/ProductCarousel";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import "./css/Products.css";
import cardioProductCat from "../images/Products/ProductCat/cardioProductCat.webp";
import diabeticProductCat from "../images/Products/ProductCat/diabeticProductCat.webp"
import neurologyProductCat from "../images/Products/ProductCat/neurologyProductCat.webp"
import orthopedicProductCat from "../images/Products/ProductCat/orthopedicProductCat.webp"
import womenHealthProductCat from "../images/Products/ProductCat/womenhealthProductCat.webp"
import pediatricProductCat from "../images/Products/ProductCat/pediatricsProductCat.webp"


// Cardio Images
import {
  cardio_img1,
  cardio_img2,
  cardio_img3,
  cardio_img4,
  cardio_img5,
  cardio_img6,
} from "../images/Products/Cardio";
//Diabetic Images
import {
  diabetic_img1,
  diabetic_img2,
  diabetic_img3,
  diabetic_img4,
  diabetic_img5,
  diabetic_img6,
} from "../images/Products/Diabetic";



//Neuro images

import {
  neuro_img1,
  neuro_img2,
  neuro_img3,
  neuro_img4,
  neuro_img5,
  neuro_img6
} from "../images/Products/Neuro";

//Ortho Images
import {
  ortho_img1,
  ortho_img2,
  ortho_img3,
  ortho_img4,
  ortho_img5,
  ortho_img6
}from "../images/Products/Ortho"
// Pediatrics Images
import {
  pediatric_img1,
  pediatric_img2,
  pediatric_img3,
  pediatric_img4,
  pediatric_img5,
} from "../images/Products/Pediatrics";
// Women's Health Images
import {
  women_img1,
  women_img2,
  women_img3,
  women_img4,
  women_img5,
} from "../images/Products/Women Health";

function Products() {
  const cardioCarousel = [
    cardio_img1,
    cardio_img2,
    cardio_img3,
    cardio_img4,
    cardio_img5,
    cardio_img6,
  ];
  const diabeticCarousel = [
    diabetic_img1,
    diabetic_img2,
    diabetic_img3,
    diabetic_img4,
    diabetic_img5,
    diabetic_img6,
  ];

  const neuroCarousel = [
    neuro_img1,
    neuro_img2,
    neuro_img3,
    neuro_img4,
    neuro_img5,
    neuro_img6
  ]
  

  const orthoCarousel = [
    ortho_img1,
    ortho_img2,
    ortho_img3,
    ortho_img4,
    ortho_img5,
    ortho_img6

  ];

  const pediatricCarousel = [
    pediatric_img1,
    pediatric_img2,
    pediatric_img3,
    pediatric_img4,
    pediatric_img5,
  ];
  const womenCarousel = [
    women_img1,
    women_img2,
    women_img3,
    women_img4,
    women_img5,
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animateProductSubHeading');
          } else {
            entry.target.classList.remove('animateProductSubHeading');
          }
        });
      },
      { threshold: 0.5 }
    );

    const the_animation = document.querySelectorAll('.animateProductSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }

    return () => {
      observer.disconnect();
    };
  }, []); 

  return (
    <main>
      <Navbar />

      {/* Hero Section */}
      <div className="product-hero">
        <div className="product-hero-title">
          <h1 className="productsAnimateHeading" >Our Products</h1>
          <p className="productsAnimateHeading" >Elevating Health, Enriching Lives.</p>
        </div>
      </div>

      {/* Cardio Section */}
      <div className="left-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-content">
              <h2 className="animateProductSubHeading" >Cardio</h2>
              <br />
              <p>
                Our cutting-edge Cardio Medicines are expertly formulated to
                support heart health and manage cardiovascular conditions. With
                a focus on safety and efficacy, our advanced solutions provide
                effective care for optimal cardiovascular function. Trust in our
                commitment to your heart's well-being and choose our Cardio
                Medicines for exceptional care.
              </p>
            </div>

            <div className="product-main-photo">
              <img src={cardioProductCat} alt="cardio" />
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={cardioCarousel} prodColor="#EAF4FA" />
          </div>
        </div>
      </div>

      <div className="right-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-main-photo">
              <img src={diabeticProductCat} alt="cardio" />
            </div>

            <div className="product-content">
              <h2 className="animateProductSubHeading" >Diabetic</h2>
              <br />
              <p>
                Our state-of-the-art Diabetic Medicines are specifically crafted
                to help manage diabetes effectively. With a focus on precision
                and innovation, our advanced solutions assist in maintaining
                optimal blood sugar levels. Trust our expertise and choose our
                Diabetic Medicines for comprehensive care in your diabetes
                management journey.
              </p>
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={diabeticCarousel} prodColor="white" />
          </div>
        </div>
      </div>
      <div className="left-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-content">
              <h2 className="animateProductSubHeading">Neurology</h2>
              <br />
              <p>
                Our Neurology Medicines are expertly formulated to address
                neurological conditions and promote brain health. Developed by
                leading specialists, our advanced solutions offer effective
                support for various neurological disorders. Count on our
                Neurology Medicines to provide targeted care and enhance your
                neurological well-being.
              </p>
            </div>

            <div className="product-main-photo">
              <img src={neurologyProductCat} alt="cardio" />
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={neuroCarousel} prodColor="#EAF4FA" />
          </div>
        </div>
      </div>

      <div className="right-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-main-photo">
              <img src={orthopedicProductCat} alt="cardio" />
            </div>

            <div className="product-content">
              <h2 className="animateProductSubHeading" >Orthopedics</h2>
              <br />
              <p>
                Designed to address orthopaedic conditions, our advanced
                Orthopaedic Medicines offer comprehensive support for bone and
                joint health. With a focus on precision and effectiveness, our
                solutions help alleviate pain, promote healing, and improve
                mobility. Trust in our Orthopaedic Medicines for exceptional
                care and a healthier, more active lifestyle.
              </p>
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={orthoCarousel} prodColor="white" />
          </div>
        </div>
      </div>
      <div className="left-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-content">
              <h2 className="animateProductSubHeading" >Women Health</h2>
              <br />
              <p>
                Our Women Health Medicines are thoughtfully designed to meet the
                unique healthcare needs of women. With a focus on holistic
                well-being, our advanced solutions support reproductive health,
                hormonal balance, and overall vitality. Trust our Women Health
                Medicines to empower you with optimal care and enhance your
                overall health and wellness.
              </p>
            </div>

            <div className="product-main-photo">
              <img src={womenHealthProductCat} alt="cardio" />
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={womenCarousel} prodColor="#EAF4FA" />
          </div>
        </div>
      </div>

      <div className="right-products">
        <div className="productSections">
          <div className="upper">
            <div className="product-main-photo">
              <img src={pediatricProductCat} alt="cardio" />
            </div>

            <div className="product-content">
              <h2 className="animateProductSubHeading" >Pediatrics</h2>
              <br />
              <p>
                Our Pediatrics Medicines are carefully formulated to cater to
                the specific healthcare needs of children. Developed with utmost
                care and precision, our advanced solutions provide safe and
                effective treatment for common childhood ailments. Count on our
                Pediatrics Medicines to ensure the well-being and healthy
                development of your little ones.
              </p>
            </div>
          </div>

          <div className="lower">
            <ProductCarousel listOfImgs={pediatricCarousel} prodColor="white" />
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </main>
  );
}

export default Products;
