import React from "react";
import "./css/ProductBox.css";
import Button from "./Button";

function ProductBox({ title, imgLink }) {
  return (
    <div className="product-box">
      <div className="content">
        <div className="prod-img">
          <img src={imgLink} alt="Product" />
        </div>
        <div className="sub-content">
          <h3>{title}</h3>
          <a href="/products">
            <Button cName="secondary-btn" text="View Products" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProductBox;
