import { useEffect, useState } from "react";

function useFetch(id) {
  const SPREADSHEET_ID = "1naakiQY_jsX_nzexZhAvYsdBSXDG-Ze_4Jb0NtfeL6E"; //New Page
  const API_KEY = "AIzaSyD3UF_fDwiSqJcBqwMZALJecV-JSIc-LoM"; // old api
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exists, setExists] = useState(null);
  let url = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Sheet1?key=${API_KEY}`;

  useEffect(() => {
    fetch(url)
      .then((res) => {
        // console.log('THis is no fetch error')
        if (res.status !== 200) {
          setError(true);
          throw new Error("Something went wrong!");
        }
        return res.json();
      })
      .then((data) => {
        // Check if the data object has a 'values' key.
        if (!data.hasOwnProperty("values")) {
          setLoading(false);
          setExists(false);
        } else {
          let sheetArray = data.values;
          const jsonData = sheetArray.slice(1).map((value, index) => ({
            id: index + 1,
            timestamp: value[0],
            title: value[1],
            content: value[2],
            author: value[3],
            date: value[4],
            time: value[5],
            photo1: value[6],
            photo2: value[7],
            all: value,
          }));
          console.log(sheetArray.length);
          if (sheetArray.length === 1) {
            setError(true);
            setExists(false);
          } else {
            setData(jsonData);
            setExists(true);
          }
          setLoading(false);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, []);

  return { data, loading, error, exists };
}

export default useFetch;
