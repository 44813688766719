import React from "react";
import Carousel from "react-elastic-carousel";
import "./css/ProductCarousel.css";

function ProductCarousel({ listOfImgs, prodColor }) {
  let carouselRef = React.useRef(null);

  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };

  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(5);
    }
  };
  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 480, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
  ];
  return (
    <div className="product-carousel">
      <Carousel
        ref={carouselRef}
        breakPoints={breakpoints}
        pagination={false}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
      >
        {listOfImgs.map((imgLink) => (
          <div
            className="product-img-card"
            style={{ backgroundColor: prodColor }}
          >
            <img src={imgLink} alt="Product-Image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ProductCarousel;
