import React, {useEffect} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";

import Button from "../components/Button";

import values_heart from "../images/ContactUs/1web.webp";
import values_thumbsup from "../images/ContactUs/2web.webp";
import values_star from "../images/ContactUs/3web.webp";
import values_chart from "../images/ContactUs/4web.webp";
import values_group from "../images/ContactUs/5web.webp";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

// import emailjs from "emailjs-com";
import emailjs from "@emailjs/browser";

import "./css/ContactUs.css";

function ContactUs() {


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('contactAnimateSubHeading');
          } else {
            entry.target.classList.remove('contactAnimateSubHeading');
          }
        });
      },
      { threshold: 0.5 }
    );

    const the_animation = document.querySelectorAll('.contactAnimateSubHeading');
    for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];
      observer.observe(elements);
    }

    return () => {
      observer.disconnect();
    };
  }, []); 

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_nxn3fag",
        "template_6kmid79",
        e.target,
        "BSUduqTr7xN5BIfU0"
      )
      .then(() => alert("Contact Us Information Received"));
  }

  return (
    <main>
      <Navbar />
      <div className="contact-hero">
        <div className="contactHeroTitle">
          <div>
            <h1 className="contactAnimateHeading" >Get In Touch📞</h1>
          </div>
          <div>
            <h3 className="contactAnimateHeading">Unbriddle your Talent with Us</h3>
          </div>
        </div>
      </div>

      <br />

      <div className="career-with-us">
        <div className="career-content">
          <h2 className="contactAnimateSubHeading">Why Career with us?</h2>
          <div className="career-grids">
            <div class="career-grid-container-1">
              <div class="career-tile tile-1">
                <div class="career-header">
                  <h3>
                    <img src={values_thumbsup} alt="" />
                  </h3>
                </div>
                <div class="career-body">
                  <p>Positive Atmosphere</p>
                </div>
              </div>
              <div class="career-tile tile-2">
                <div class="career-header">
                  <h3>
                    <img src={values_heart} alt="" />
                  </h3>
                </div>
                <div class="career-body">
                  <p>Opportunity to Serve Humanity</p>
                </div>
              </div>
              <div class="career-tile tile-3">
                <div class="career-header">
                  <h3>
                    <img src={values_star} alt="" />
                  </h3>
                </div>
                <div class="career-body">
                  <p>Proper Appreciation</p>
                </div>
              </div>
            </div>
            <div class="career-grid-container-2">
              <div class="career-tile tile-4">
                <div class="career-header">
                  <h3>
                    <img src={values_chart} alt="" />
                  </h3>
                </div>
                <div class="career-body">
                  <p>Positive Growth in Career</p>
                </div>
              </div>
              <div class="career-tile tile-5">
                <div class="career-header">
                  <h3>
                    <img src={values_group} alt="" />
                  </h3>
                </div>
                <div class="career-body">
                  <p>Affectionate Work Culture</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-career">
        <div className="contact-career-content">
          <h2 className="contactAnimateSubHeading">Career</h2>
          <br />
          <p>
            At Eldorado Biotech Pvt Ltd, we pride ourselves on fostering a
            professional work environment that nurtures employee growth and
            excellence. Our company values the innate curiosity of our employees
            and provides a dynamic and exciting atmosphere where career
            development takes priority. We welcome talented individuals with
            innovative thinking, offering them exceptional opportunities to make
            a meaningful impact on creating a healthier world.
          </p>
          <br />
          <p>
            With specialised departments such as Medical Affairs, Sales and
            Marketing, Product Management, and Accounts and Administration, we
            provide diverse career paths for aspiring professionals. By joining
            Eldorado Biotech Pvt Ltd, you will have the chance to contribute to
            society while building a rewarding career. We encourage our
            employees to unleash their full potential, empowering them to make a
            difference in the lives of others through their work.
          </p>
        </div>
      </div>

      <div className="contact-form-section">
        <div className="full-contact">
          <div className="contact-form-content">
            <div className="first-box">
              <h3>Contact Info.</h3>
              <div className="formText">
                <div className="locationIcon form-icons">
                  <LocationOnIcon />
                </div>
                <div className="locationText">
                  <p>
                    A-Citius, 405, Space Olympia, Sutgirni Chowk, Aurangabad,
                    Maharashtra, 431001
                  </p>
                </div>
              </div>
              <div className="formText">
                <div className="callIcon form-icons">
                  <CallIcon />
                </div>
                <div className="phone">
                  <p>+91-240-2983136</p>
                </div>
              </div>
              <div className="formText">
                <div className="EmailIcon form-icons">
                  <EmailIcon />
                </div>
                <div className="EmailText">
                  <p>info@eldoradobiotech.com</p>
                </div>
              </div>
            </div>

            <div className="second-box">
              <div className="second-box-content">
                <h2 className="contactAnimateSubHeading" >Send Message</h2>
                <br />
                <br />
                <form onSubmit={sendEmail}>
                  <div className="sidebyside">
                    {/* Enter First Name */}
                    <div class="form__group field">
                      <input
                        type="text"
                        class="form__field"
                        placeholder="Name"
                        required
                        minLength={3}
                        name="firstname"
                        id="firstname"
                      />
                      <label for="name" class="form__label">
                        First Name
                      </label>
                    </div>

                    {/* Enter Last Name */}

                    <div class="form__group field">
                      <input
                        type="text"
                        class="form__field"
                        placeholder="Last Name"
                        minLength={3}
                        name="lastname"
                        id="lastname"
                      />
                      <label for="lastName" class="form__label">
                        Last Name
                      </label>
                    </div>
                  </div>

                  <br />
                  <br />

                  {/* Enter Email */}

                  <div className="sidebyside">
                    <div class="form__group field">
                      <input
                        type="email"
                        id="email"
                        class="form__field"
                        placeholder="Email"
                        name="email"
                        required
                      />
                      <label for="Email" class="form__label">
                        Email Address
                      </label>
                    </div>

                    {/* Enter Phone Number */}
                    <div class="form__group field">
                      <input
                        type="tel"
                        class="form__field"
                        placeholder="Mobile Number"
                        required
                        minLength="10"
                        maxLength="15"
                        name="mobile"
                        id="mobile"
                      />
                      <label for="lastName" class="form__label">
                        Mobile Number
                      </label>
                    </div>
                  </div>

                  <br />
                  <br />

                  {/* Enter Comments */}

                  <div class="form__group field messagebox">
                    <textarea
                      class="form__field"
                      id="message"
                      rows="3"
                      // cols=""
                      placeholder="Comments"
                      name="message"
                      required
                    />
                    <label for="Comments" class="form__label">
                      Write your message here...
                    </label>
                  </div>
                  <div className="sendBtn">
                    <Button cName="primary-btn" text="Send" type="submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <Copyright />
    </main>
  );
}

export default ContactUs;
